import { createContext, FC, useEffect, Dispatch, SetStateAction, useState } from 'react';

import { CourseDataFragment as Course, useCoursesQuery, CourseDataFragment } from '@codegen';

import Loader from 'components/Loader';

import { useLocalStorage } from 'utils/hooks';

export const AppContext = createContext<{
  courses: Course[];
  activeCourse: Course | null;
  setActiveCourse: Dispatch<SetStateAction<CourseDataFragment | null>> | null;
  setActiveCourseId: Dispatch<SetStateAction<string | null>> | null;
}>({
  courses: [],
  activeCourse: null,
  setActiveCourse: null,
  setActiveCourseId: null,
});

const AppContextProvider: FC = ({ children }) => {
  const { data } = useCoursesQuery();
  const [activeCourseId, setActiveCourseId] = useLocalStorage<Course['id'] | null>(
    'activeCourseId',
    null,
  );
  const [activeCourse, setActiveCourse] = useState<Course | null>(null);

  useEffect(() => {
    if (data?.courses) {
      const id = activeCourseId || data.courses[0].id;
      setActiveCourseId(id);
    }
  }, [data]);

  useEffect(() => {
    const course = data?.courses.find(c => c.id === activeCourseId);

    if (course) {
      setActiveCourse(course);
    }
  }, [data, activeCourseId]);

  if (!activeCourse) {
    return <Loader size="large" center />;
  }

  return (
    <AppContext.Provider
      value={{ activeCourse, setActiveCourse, setActiveCourseId, courses: data?.courses || [] }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
