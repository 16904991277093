import React from 'react';
import { isStaging, isProduction } from 'utils/helpers';

import ErrorComponent from '../../Error';
export interface ErrorInfo {
  componentStack: any;
  [key: string]: any;
}

export interface FallbackComponentProps {
  error: Error;
  info: ErrorInfo;
}

const AppError = ({ error, info }: FallbackComponentProps) => {
  if (isStaging || isProduction) {
    return <ErrorComponent />;
  }
  return (
    <div>
      <b>{error.message}</b>
      {info.componentStack.split(/\r?\n/).map((line: React.ReactNode, i: number) => (
        <p key={`trace-line-${i}`}>{line}</p>
      ))}
    </div>
  );
};

export default AppError;
