import { FC, ReactNode } from 'react';
import { ButtonProps } from 'antd/lib/button/button';

import * as S from './styles';
import { radii } from '../../theme';

export enum Sizes {
  'medium-narrow' = 'medium-narrow',
  medium = 'medium',
  small = 'small',
  sameheight = 'sameheight',
  verysmall = 'verysmall',
}

export enum Variants {
  supernova = 'supernova',
  cosmic = 'cosmic',
  'black-outline' = 'black-outline',
  'white-outline' = 'white-outline',
  'black' = 'black',
  'dark-gray' = 'dark-gray',
  surfie = 'surfie',
}

export enum IconPlacements {
  left = 'left',
  right = 'right',
}

export type VariantType = keyof typeof Variants;
export type RadiusType = keyof typeof radii;
export type SizeType = keyof typeof Sizes;
export type IconPlacementType = keyof typeof IconPlacements;

export interface Props extends Omit<ButtonProps, 'size'> {
  variant?: VariantType;
  radius?: RadiusType;
  size?: SizeType;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: ReactNode;
  iconPlacement?: IconPlacementType;
  lineHeight?: number;
}

const Button: FC<Props> = ({
  children,
  radius = 'button',
  variant = 'supernova',
  size = 'medium',
  disabled = false,
  fullWidth = false,
  lineHeight = 1.5,
  iconPlacement = 'left',
  ...props
}) => {
  return (
    <S.Button
      {...props}
      variant={variant}
      radius={radius}
      csize={size}
      disabled={disabled}
      fullWidth={fullWidth}
      lineHeight={lineHeight}
      iconPlacement={iconPlacement}
    >
      {children}
    </S.Button>
  );
};

export default Button;
