import React, { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import Loader from 'components/Loader';

import ROUTES from 'utils/routes';

import { UserContext } from './userContext';

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const PageLoader = (
  <Wrap>
    <Loader size="large" />
  </Wrap>
);

const CustomRouter: React.FC = ({ children }) => {
  const { user, userLoading } = useContext(UserContext);
  const { route, push } = useRouter();

  useEffect(() => {
    if (userLoading) return;
    if (!user && route !== ROUTES.signIn) {
      push(ROUTES.signIn);
    } else if (user && route === ROUTES.signIn) {
      push(ROUTES.index);
    }
  }, [route, user, userLoading]);

  return <>{userLoading ? PageLoader : children}</>;
};
export default CustomRouter;
