import styled from 'styled-components';
import { Button as AntButton } from 'antd';

import * as t from '../../theme';
import { VariantType, RadiusType, SizeType, IconPlacementType } from './Button';

interface Props {
  variant: VariantType;
  radius: RadiusType;
  csize: SizeType;
  fullWidth: boolean;
  lineHeight: number;
  iconPlacement: IconPlacementType;
}

const handleVariant = ({ colors }: typeof t, variant: VariantType) =>
  ({
    supernova: `
      background-color: ${colors.supernova};
      color: ${colors.black};
      border: 1px solid ${colors.supernova};
      &:active {
        filter: brightness(0.95);
      }
    `,
    cosmic: `
      background-color: ${colors.cosmic};
      color: ${colors.white};
      border: 1px solid ${colors.cosmic};
      &:active {
        filter: brightness(0.9);
      }
    `,
    'black-outline': `
      background-color: ${colors.white};
      border: 1px solid ${colors.black};
      color: ${colors.black};
      &:active {
        filter: brightness(0.95);
      }
    `,
    'white-outline': `
      background-color: ${colors.white};
      border: 1px solid ${colors.white};
      color: ${colors.black};
      &:active {
        filter: brightness(0.95);
      }
    `,
    'dark-gray': `
      background-color: ${colors.darkGray};
      border: 1px solid ${colors.darkGray};
      color: ${colors.white};
      &:active {
        filter: brightness(0.95);
      }
    `,
    surfie: `
      background-color: ${colors.surfie};
      border: 1px solid ${colors.surfie};
      color: ${colors.white};
      &:active {
        filter: brightness(0.95);
      }
    `,
    'black': `
      background-color: ${colors.black};
      border: 1px solid ${colors.white};
      color: ${colors.white};
      &:active {
        filter: brightness(0.95);
      }
    `,
  }[variant]);

const handleSize = ({ fontWeights }: typeof t, size: SizeType) =>
  ({
    'medium-narrow': `
  padding: 8px 26px;
  font-weight: ${fontWeights.bold};
`,
    medium: `
    padding: 8px 35px;
    font-weight: ${fontWeights.bold};
  `,
    small: `
    padding: 5px 8px;
    font-weight: ${fontWeights.normal};
  `,
  verysmall: `
  padding: 0px 5px;
  font-weight: ${fontWeights.normal};
`,
  sameheight: `
  width: 35px;
  height: 35px;
  font-weight: ${fontWeights.normal};
`,
  }[size]);

export const Button = styled(AntButton).withConfig({
  shouldForwardProp: prop => !['fullWidth', 'lineHeight', 'iconPlacement'].includes(prop),
})<Props>`
  && {
    &,
    &:hover,
    &:focus,
    &:disabled,
    &:disabled:hover {
      ${({ theme, variant }) => handleVariant(theme, variant)};
      ${({ theme, csize }) => handleSize(theme, csize)};
    }

    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
    border-radius: ${({ theme, radius }) => theme.radii[radius]};
    line-height: ${({ lineHeight }) => lineHeight};
    transition: transform 0.15s;
    height: auto;
    font-size: 16px;
    white-space: normal;

    &:hover,
    &:active {
      box-shadow: ${({ theme }) => theme.shadows.elevation};
    }

    &:disabled {
      opacity: 0.5;
      box-shadow: none;
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }

    svg + * {
      display: inline-block;
      vertical-align: middle;
    }

    ${({ iconPlacement }) =>
      iconPlacement === 'right' &&
      `
    flex-flow: row-reverse;

    svg {
      margin-left: 10px;
      margin-right: 0;
    }
  `}
  }
`;
